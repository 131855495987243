import {
  Box,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import React from "react";

function MultiSelect({
  id,
  label,
  items,
  selectedItems,
  handleChange,
  handleDelete,
}) {
  return (
    <React.Fragment>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}-label`}
        name={`${id}`}
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput id={`select-${id}`} label={`${label}`} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                clickable
                deleteIcon={
                  <HighlightOffIcon
                    onMouseDown={(event) => event.stopPropagation()}
                    fontSize="small"
                    style={{ color: "#B3B3B3" }}
                  />
                }
                onDelete={() => handleDelete(value)}
              />
            ))}
          </Box>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {item}{" "}
            {selectedItems.find((element) => element === item) && (
              <HighlightOffIcon fontSize="small" style={{ color: "#B3B3B3" }} />
            )}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}

export default MultiSelect;
