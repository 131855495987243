import React from "react";
import "../../../styles/SelectedInvestigation.scss";
import { Button } from "@mui/material";
// import AutorenewIcon from "@mui/icons-material/Autorenew";

function SelectedInvestigation({ investigation }) {
  return (
    <div id="selected-investigation">
      <div id="information">
        <div className="items-wrapper">
          <div className="item">
            <span className="mister">Title</span>
            <p>{investigation.title}</p>
          </div>
          <div className="item">
            <span className="mister">Topic</span>
            <p>{investigation.topic}</p>
          </div>
        </div>
        <div className="items-wrapper">
          <div className="item">
            <span className="mister">Created at</span>
            <p>{investigation.createdAt}</p>
          </div>
          <div className="item">
            <span className="mister">last updated</span>
            <div className="info-with-action">
              <p>{investigation.lastUpdated} </p>
              {/* <button>
                <AutorenewIcon fontSize="small" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div id="actions">
        {/* <Button variant="contained">Export PDF</Button> */}
        <Button variant="contained">Delete</Button>
      </div>
    </div>
  );
}

export default SelectedInvestigation;
