import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../styles/CreateNewInvestigation.scss";
import { Button } from "@mui/material";
import React, { useState } from "react";
import MultiSelect from "../../components/FormElements/MultiSelect";
import { Link } from "react-router-dom";

function CreateNewInvestigation() {
  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    identidiedDfnPost: "",
    investigationViewers: [],
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      formData.title.replace(/\s/g, "") !== "" &&
      formData.topic.replace(/\s/g, "") !== "" &&
      formData.identidiedDfnPost.replace(/\s/g, "") !== "" &&
      formData.investigationViewers.length !== 0
    ) {
      setSuccess(true);
      setWarning(false);
    } else {
      setWarning(true);
      setSuccess(false);
    }
  };

  const handleDiscard = (event) => {
    event.preventDefault();
    setFormData({
      title: "",
      topic: "",
      identidiedDfnPost: "",
      investigationViewers: [],
    });
    setError(false);
    setSuccess(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (["investigationViewers"].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value.split(",") : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDeleteViewer = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      investigationViewers: prevData.investigationViewers.filter(
        (item) => item !== value
      ),
    }));
  };

  const users = ["User 1", "User 2", "User 3", "User 4"];

  return (
    <div id="new-investigation-wrapper">
      <form>
        <h1>Add Investigation</h1>
        <FormControl fullWidth>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
            placeholder="Provide here a title for the new investigation"
          />
        </FormControl>
        <FormControl>
          <FormLabel id="investigation-topic">
            Select the investigation topic:
          </FormLabel>
          <RadioGroup
            aria-labelledby="investigation-topic"
            name="topic"
            onChange={handleChange}
          >
            <FormControlLabel
              value="farRight"
              control={<Radio />}
              label="Political interference from extremists on the Far-right wing"
            />
            <FormControlLabel
              value="healthCrisis"
              control={<Radio />}
              label="Health crisis: riots and different forms of violence (COVID-19)"
            />
            <FormControlLabel
              value="farLeft"
              control={<Radio />}
              label="Extremist propaganda socio-political destabilization and real terrorist attacks from the Far-left wing"
            />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Identified D&FN post"
            name="identidiedDfnPost"
            value={formData.identidiedDfnPost}
            onChange={handleChange}
            fullWidth
            required
            placeholder="Provide here the URL of the identified D&FN post (eg. https://twitter.com/kolowe/status/16825641454813994)"
          />
        </FormControl>
        <FormControl fullWidth>
          <MultiSelect
            id={"investigationViewers"}
            label={"Investigation's Viewers"}
            items={users}
            selectedItems={formData.investigationViewers}
            handleChange={handleChange}
            handleDelete={handleDeleteViewer}
          />
        </FormControl>

        {error && (
          <Alert severity="error">
            There was a problem submitting your Investigation data!
          </Alert>
        )}
        {success && (
          <Alert severity="success">
            Investigation was successfully submitted!
          </Alert>
        )}

        {warning && (
          <Alert severity="warning">
            You should fill in all the necessary fields!
          </Alert>
        )}

        <FormControl>
          <div className="form-buttons">
            <Button
              variant="contained"
              onClick={handleDiscard}
              id="whiteButton"
            >
              <Link to="/">Cancel</Link> 
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Create investigation
            </Button>
          </div>
        </FormControl>
      </form>
    </div>
  );
}

export default CreateNewInvestigation;
