import React, { useState } from "react";
import "../../../styles/Sidebar.scss";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SearchIcon } from "./search-interface-symbol 1.svg";
import { Link } from "react-router-dom";
import Select from "../../../components/FormElements/Select";

function Sidebar({
  investigationData = [],
  selectInvestigation,
  selectedInvestigation,
}) {
  const compareDates = (a, b) => {
    let dateStringA = a.createdAt;
    let dateStringB = b.createdAt;
    let [dayA, monthA, yearA] = dateStringA.split("/");
    const dateObjA = new Date(+yearA, +monthA - 1, +dayA);
    let [dayB, monthB, yearB] = dateStringB.split("/");
    const dateObjB = new Date(+yearB, +monthB - 1, +dayB);
    const compareResult = dateObjA - dateObjB;
    return compareResult;
  };

  const [data, setData] = useState(
    investigationData.slice().sort(compareDates).reverse()
  );
  const [sortValue, setSortValue] = useState("recent");

  const sortOptions = [
    { name: "recent", title: "Recent" },
    { name: "older", title: "Older" },
  ];

  const topicOptions = [
    { name: "all", title: "All" },
    { name: "covid", title: "Covid-19" },
    { name: "far-right", title: "Far right wing" },
    { name: "far-left", title: "Far left wing" },
  ];

  const handleSortChange = (sortType) => {
    const sortBy = sortType.name;
    setSortValue(sortBy);
    sortData(data, sortBy);
  };

  const sortData = (items, type) => {
    if (type === "recent") {
      setData(items.slice().sort(compareDates).reverse());
    } else if (type === "older") {
      setData(items.slice().sort(compareDates));
    }
  };

  const handleTopicChange = (topicType) => {
    const topic = topicType.name;
    let tempData = [];
    if (topic === "all") {
      tempData = investigationData;
    } else {
      tempData = investigationData.filter((element) =>
        element.topic.toLowerCase().includes(topic)
      );
    }
    sortData(tempData, sortValue);
  };

  const getTopicLabel = (topic) => {
    var topicLabel = "";

    if (topic) {
      if (topic.toLowerCase().includes("covid")) {
        topicLabel = "COVID-19";
      } else if (topic.toLowerCase().includes("far-left")) {
        topicLabel = "far Left wing";
      } else if (topic.toLowerCase().includes("far-right")) {
        topicLabel = "far right wing";
      }
    }

    if (topicLabel.length > 0) {
      return topicLabel + " | ";
    } else {
      return topicLabel;
    }
  };
  return (
    <div id="sidebar">
      <div className="top-section">
        <h2>List of investigations</h2>

        <Button variant="contained">
          <AddIcon id="icon" />
          <Link to="/create-new-investigation">Create new</Link>
        </Button>

        <div id="search">
          <input type="text" placeholder="Search" />
          <SearchIcon />
        </div>

        <div id="filters">
          <div className="filter">
            <Select
              title="Sort by"
              options={sortOptions}
              handleChange={handleSortChange}
            />
          </div>
          <div className="filter">
            <Select
              title="Topic"
              options={topicOptions}
              handleChange={handleTopicChange}
            />
          </div>
        </div>
      </div>

      <ul>
        {data.map((element) => (
          <li
            key={element.id}
            className={element.id === selectedInvestigation ? "active" : ""}
            onClick={() => selectInvestigation(element.id)}
          >
            <div className="labels mister">
              <span>{getTopicLabel(element.topic)}</span>
              <span>{element.createdAt}</span>
            </div>
            <Tooltip title={element.title}>
              <div>{element.title}</div>
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
