import React from "react";
import "../../../styles/ColorsTable.scss";

function ColorsTable({ colors = [], centered = false, square = false }) {
  return (
    <div className={`colors-table ${centered ? "centered" : ""}`}>
      {colors.map((color, index) => (
        <div className="color-item" key={index}>
          <div
            className={`color ${square ? "square" : ""}`}
            style={{ backgroundColor: color.colorCode }}
          ></div>
          <span>{color.details}</span>
        </div>
      ))}
    </div>
  );
}

export default ColorsTable;
