import React, { useEffect, useState } from "react";
import PieChart from "../PieChart/PieCart";
import "../../styles/OverallSentment.scss";

function OverallSentimentScore({ nodes }) {
  const [loading, setLoading] = useState(true);
  const [sentimentData, setSentimentData] = useState({
    totalTweets: 0,
    validTweets: 0,
    positive: 0,
    neutral: 0,
    negative: 0,
  });
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if (nodes.nodes.length > 0) {
      const validTweets = nodes.nodes.filter((element) => {
        if (element.properties.prediction_label) {
          return true;
        } else {
          return false;
        }
      });

      const positive = validTweets.filter(
        (element) => element.properties.prediction_label === "positive"
      ).length;
      const neutral = validTweets.filter(
        (element) => element.properties.prediction_label === "neutral"
      ).length;

      const negative = validTweets.filter(
        (element) => element.properties.prediction_label === "negative"
      ).length;

      if (validTweets.length > 0) {
      }

      const tempSentimentData = {
        totalTweets: nodes.nodes.length,
        validTweets: validTweets.length,
        positive: limitDecimalsWithoutRounding(
          (positive / validTweets.length) * 100
        ),
        neutral: limitDecimalsWithoutRounding(
          (neutral / validTweets.length) * 100
        ),
        negative: limitDecimalsWithoutRounding(
          (negative / validTweets.length) * 100
        ),
      };

      setSentimentData(tempSentimentData);
      setChartData([
        { name: "Positive", value: tempSentimentData.positive },
        { name: "Neutral", value: tempSentimentData.neutral },
        { name: "Negative", value: tempSentimentData.negative },
      ]);
      setLoading(false);
    }
  }, [nodes]);

  function limitDecimalsWithoutRounding(number) {
    const factor = Math.pow(10, 2);
    const truncatedValue = Math.floor(number * factor) / factor;
    return truncatedValue;
  }

  return (
    <div id="overall-sentiment-score-wrapper">
      {!loading && (
        <div>
          {sentimentData.validTweets === 0 ? (
            <p>There are no tweets with sentiment analysis</p>
          ) : (
            <>
              <p>
                Total tweets with sentiment analysis:{" "}
                <span>{sentimentData.validTweets}</span>
              </p>
              <p>
                Unknown:{" "}
                <span>
                  {sentimentData.totalTweets - sentimentData.validTweets}
                </span>
              </p>
              <div id="sentiment-legend">
                <div className="legend-item">
                  <div className="percentage positive mister">
                    {sentimentData.positive}%
                  </div>
                  <span>Positive</span>
                </div>
                <div className="legend-item">
                  <div className="percentage neutral mister">
                    {sentimentData.neutral}%
                  </div>
                  <span>Neutral</span>
                </div>
                <div className="legend-item">
                  <div className="percentage negative mister">
                    {sentimentData.negative}%
                  </div>
                  <span>Negative</span>
                </div>
              </div>
            </>
          )}
          <PieChart chartData={chartData} name={"overall-sentiment-chart"} />
        </div>
      )}
    </div>
  );
}

export default OverallSentimentScore;
