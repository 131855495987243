import "./App.css";
import NavigationBar from "./components/Navigation/NavigationBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import CreateNewInvestigation from "./pages/CreateNewInvestigation/CreateNewInvestigation";
// import { Container } from "@mui/material";
import Footer from "./components/Footer/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import "./fonts/Roboto/Roboto-Regular.ttf";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <NavigationBar />
          {/* <Container maxWidth="xl"> */}
            {/* <div id="main-page-content"> */}
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                  path="/create-new-investigation"
                  element={<CreateNewInvestigation />}
                />
                <Route path="*" element={<LandingPage />} />
              </Routes>
            {/* </div> */}
          {/* </Container> */}
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
