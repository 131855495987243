import React, { useState } from "react";
import "../../styles/LandingPage.scss";
import investigationData from "../../data/investigationData.json";
import NodeGraph from "../../components/NodeGraph/NodeGraph";
import graphData from "../../data/nodes.json";
import Sidebar from "./Sidebar/Sidebar";
import SelectedInvestigation from "./SelectedInvestigation/SelectedInvestigation";
import SentimentGraph from "../../components/SentimentGraph/SentimentGraph";
import OverallSentimentScore from "../../components/OverallSentimentScore/OverallSentimentScore";
import MapChart from "../../components/MapChart/MapChart";
import TweetImpact from "./TweetImpact/TweetImpact";
import Recommendations from "../../components/Recommendations/Recommendations";

function LandingPage() {
  const [selectedInvestigation, setSelectedInvestigation] = useState(1);

  const selectInvestigation = (id) => {
    setSelectedInvestigation(id);
  };

  return (
    <div className="landing-page-wrapper page-container">
      <Sidebar
        investigationData={investigationData}
        selectInvestigation={selectInvestigation}
        selectedInvestigation={selectedInvestigation}
      />
      <div id="content">
        <section>
          <h2>Selected Investigation</h2>
          <SelectedInvestigation
            investigation={investigationData.find(
              (element) => element.id === selectedInvestigation
            )}
          />
        </section>

        <section>
          <h2>Investigation Details</h2>
          <NodeGraph nodeData={graphData} />
        </section>

        <section>
          <div id="sentiment-section">
            <div id="overall">
              <h3>Overall sentiment score</h3>
              <OverallSentimentScore nodes={graphData} />
            </div>
            <div id="overtime">
              <h3>Sentiment score over time</h3>
              <SentimentGraph nodes={graphData.nodes} />
            </div>
          </div>
        </section>

        <section>
          <h3>Crimes predictor</h3>
          <MapChart />
        </section>

        <section>
          <h3>Recommendations / Countermeasures for Community Resilience</h3>
          <div id="recommendations-section">
            <div id="tweet-impact">
              <TweetImpact impact="low" />
            </div>
            <div id="countemeasures">
              <Recommendations />{" "}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LandingPage;
