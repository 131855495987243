import React from "react";
import "../../styles/NavigationBar.scss";
import { Link } from "react-router-dom";

function NavigationBar() {
  return (
    <div id="nav-bar-wrapper">
      <div className="nav-bar-container page-container">
        <div id="nav-main-part">
          <Link to="/">
            <img src="/fermi-logo.png" alt="Fermi Logo" id="logo" />
          </Link>
        </div>

        <div id="secondary-menu">
          <Link>
            <img src="/img/svg/User 1.svg" alt="User Icon" id="user-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
