import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../../styles/MapChartInformation.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function MapChartCountryInformation({ data = null, country }) {
  const [countryData, setCountryData] = useState({});

  useEffect(() => {
    if (data !== null) {
      setCountryData(
        data.country.find((element) => element.Country_name === country)
      );
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const createCrimePredictionRows = (data) => {
    let crimesArray = [];

    Object.keys(data.crime[0]).forEach((key) => {
      crimesArray.push({
        crimeName: key.replace("crime_", ""),
        predicted: data.crime[0][key][0].predicted_occurrence_of_crimes,
        rateOfChange: data.crime[0][key][0].rate_of_change_percentage,
      });
    });

    return (
      <React.Fragment>
        {crimesArray.map((crime, index) => (
          <tr key={index}>
            <td>{crime.crimeName}</td>
            <td>
              <div className="table-content">
                {crime.predicted}
                <>
                  {" "}
                  (
                  {crime.rateOfChange > 0 ? (
                    <ArrowUpwardIcon
                      fontSize="tiny"
                      style={{ color: "#91CC75" }}
                      className="table-icon"
                    />
                  ) : (
                    <ArrowDownwardIcon
                      fontSize="tiny"
                      style={{ color: "#EE6666" }}
                      className="table-icon"
                    />
                  )}{" "}
                  {Math.abs(crime.rateOfChange)}% )
                </>
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    );
  };

  const getDate = (data) => {
    const date = new Date(data);
    const day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    const month =
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    return day + "/" + month + "/" + date.getFullYear();
  };

  return (
    <div id="map-information">
      {countryData.Region && (
        <div className="regions">
          {countryData.Region.map((region, index) => (
            <div className="region-wrapper" key={index}>
              <p className="title">
                Region: <span>{region.Region_name}</span>
              </p>
              <div className="region-accordion">
                {region.timestamps.map((timestamp, tIndex) => (
                  <Accordion key={tIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`accordion-${tIndex}`}
                      id={`accordion-${tIndex}`}
                    >
                      <Typography>
                        <span className="acc-title">
                          Week {tIndex + 1} |{" "}
                          {getDate(timestamp.date.split("/")[0])} -{" "}
                          {getDate(timestamp.date.split("/")[1])}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tr>
                          <th>Crime</th>
                          <th>Predicted</th>
                        </tr>
                        {createCrimePredictionRows(timestamp)}
                      </table>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MapChartCountryInformation;
