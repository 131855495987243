import React, { useState, useEffect, useRef } from "react";
import "../../styles/Select.scss"; // Import your CSS file for styling
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Select({ options, title, handleChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options[0] ? options[0] : ""
  );
  const selectRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    handleChange(option);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select" ref={selectRef}>
      {options && options.length > 0 && (
        <>
          <div className="select-header" onClick={toggleDropdown}>
            <span>{title} :</span> {selectedOption.title}{" "}
            {isOpen ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </div>
          {isOpen && (
            <div className="options-container">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.title}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Select;
