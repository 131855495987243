import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

export default function PopUp({
  title,
  children,
  openPopUp,
  setOpenPopUp,
  showCloseButton = true,
  maxWidth = "md",
  fullWidth = true,
  closeFunction,
}) {
  return (
    <Dialog open={openPopUp} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle>
        {title}
        {showCloseButton === true ? (
          <div style={{ float: "right" }}>
            <Button variant="text">
              <CloseIcon
                fontSize="large"
                onClick={() => {
                  setOpenPopUp(false);
                  if (closeFunction) {
                    closeFunction();
                  }
                }}
              />
            </Button>
          </div>
        ) : null}
      </DialogTitle>
      <DialogContent> {children} </DialogContent>
    </Dialog>
  );
}
