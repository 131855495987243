import React from "react";
import ColorsTable from "../../../components/NodeGraph/NodeGraphDetails/ColorsTable";
import "../../../styles/TweetImpact.scss";

function TweetImpact({ impact }) {
  const colors = [
    { colorCode: "#91CC75", details: "Low" },
    { colorCode: "#F9C858", details: "Medium" },
    { colorCode: "#EE6666", details: "High" },
  ];
  return (
    <div className="tweet-impact-wrapper">
      <img
        src={`/img/svg/speed-sentiment/${impact}.svg`}
        alt="imapct speedometer"
      />
      <span className="title mister">TWEET IMPACT</span>
      <ColorsTable colors={colors} square={true} />
    </div>
  );
}

export default TweetImpact;
