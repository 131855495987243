import React from "react";
import "../../styles/Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="page-container">
        <div id="footer-wrapper">
          <div className="visit-section">
            <span>Visit</span>
            <Link to="/" className="footer-link">
              Disinformation watch
            </Link>
          </div>
          <div id="eu-info">
            <img src="european-union.png" alt="european union flag" />
            <div>
              This project has received funding from the European Union's HE
              research and innovation programme under the grant agreement No.
              101073980
            </div>
          </div>
          <div id="links">
            <Link to="/" className="footer-link">
              Privacy
            </Link>
            <Link to="/" className="footer-link">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
